import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

/** 全局插件引入 */
import '@/plugins';

/** 全局过滤器引入 */
import '@/filters';


Vue.config.productionTip = false;
new Vue( {
  router,
  store,
  render: h => h( App )
} ).$mount( '#app' );
