/**
 * vant 组件按需引入
 */
import Vue from 'vue';

/**
 * 导航栏
 */
import { NavBar } from 'vant';
Vue.use( NavBar );

/**
 * 轮播
 */
import { Swipe, SwipeItem } from 'vant';
Vue.use( Swipe );
Vue.use( SwipeItem );

/**
 * 公告
 */
import { NoticeBar } from 'vant';
Vue.use( NoticeBar );

/**
 * 图标
 */
import { Icon } from 'vant';
Vue.use( Icon );

/**
 * 图片
 */
import { Image as VanImage } from 'vant';
Vue.use( VanImage );

/**
 * 弹窗
 */
import { Popup } from 'vant';
Vue.use( Popup );

/**
 * 时间选择器
 */
import { DatetimePicker } from 'vant';
Vue.use( DatetimePicker );

/**
 * 上拉加载更多
 */
import { List } from 'vant';
Vue.use( List );

/**
 * 骨架屏
 */
import { Skeleton } from 'vant';
Vue.use( Skeleton );

/**
 * 按钮
 */
import { Button } from 'vant';
Vue.use( Button );

/**
 * 下拉刷新
 */
import { PullRefresh } from 'vant';
Vue.use( PullRefresh );

/**
 * 粘性布局
 */
import { Sticky } from 'vant';
Vue.use( Sticky );

/**
 * Tab 标签页
 */
import { Tab, Tabs } from 'vant';
Vue.use( Tab );
Vue.use( Tabs );

/**
 * 表单
 */
import { Form } from 'vant';
Vue.use( Form );

/**
 * Field 输入框
 */
import { Field } from 'vant';
Vue.use( Field );

/**
 * Picker 选择器
 */
import { Picker } from 'vant';
Vue.use( Picker );

// 多选筐

import { Checkbox, CheckboxGroup } from 'vant';

Vue.use( Checkbox );
Vue.use( CheckboxGroup );