<template>
  <div id="app">
    <!-- <router-view /> -->
    <h1>账号注册</h1>
    <div class="user-name-register-view">
      <van-form @submit="onSubmit">
        <van-field
          :border="false"
          maxlength="11"
          name="loginName"
          v-model="username"
          :placeholder="inputName"
          :left-icon="usernameLeftIcon"
        />
        <van-field
          :border="false"
          maxlength="8"
          name="password"
          v-model="password"
          type="password"
          :left-icon="passwordIcon"
          placeholder="请输入密码"
        />
        <van-field
          class="verifCode-view"
          :border="false"
          maxlength="4"
          name="captchaId"
          v-model="verifCode"
          type="text"
          :left-icon="codeIcon"
          placeholder="请输入验证码"
        >
          <template #button>
            <div class="identifyCode-view" @click="refreshCode">
              <SIdentify :identifyCode="identifyCode" />
            </div>
          </template>
        </van-field>
        <van-button round block type="info" native-type="submit">
          {{ inputButton }}
        </van-button>
      </van-form>
    </div>

    <div @click="openDownload" class="download-view">
      <img src="@/assets//img/download-btn.png" alt="" />
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import find from 'lodash/find';
import SIdentify from '@/components/identify/Identify';

export default {
  components: {
    SIdentify
  },
  data() {
    return {
      username: '',
      password: '',
      verifCode: '', // 验证码
      codeImage: '', // 验证码图片
      captchaId: '', // 验证码ID

      usernameLeftIcon: require( '@/assets/icon/phone.png' ),
      passwordIcon: require( '@/assets/icon/password.png' ),
      codeIcon: require( '@/assets/icon/code.png' ),

      inputName: '',
      inputButton: '',
      jumpData: {},

      identifyCodes: '1234567890',
      identifyCode: ''
    };
  },
  mounted() {
    fetch( 'https://seo.3sgp.com/api/initialize', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( {} )
    } )
      .then( response => response.json() )
      .then( data => {
        const { system_config, channel_list } = data.body;

        this.inputName = system_config[0].key_value;
        this.inputButton = system_config[1].key_value;

        const _url = window.location.hostname;
        // const _url = '01b689a.xyz';
        this.jumpData = find( channel_list, t => t.from_domain === _url );
      } )
      .catch( error => {
        console.error( error );
      } );

    this.identifyCode = '';
    this.makeCode( this.identifyCodes, 4 );
  },
  methods: {
    /**
     * @打开下载页
     */
    openDownload() {
      // const _url = 'https://hw7f.com/game';
      const _url = this.jumpData ? this.jumpData.statistics_config : 'https://hw7f.com/game';

      if ( window.parent ) {
        window.parent.location.href = _url;
      } else {
        window.open( _url );
      }
    },
    /**
     * @注册
     */
    async onSubmit( values ) {
      const { loginName, password, captchaId } = values;

      // 验证验证码
      if ( String( captchaId ) != String( this.identifyCode ) ) {
        Toast.fail( '请输入正确的验证码！' );
        return;
      }
      // 用户名
      if ( !loginName ) {
        Toast.fail( '请输入用户名！' );
        return;
      }
      //用户密码
      if ( !password ) {
        Toast.fail( '请输入用户密码！' );
        return;
      }

      // 验证通过跳转官网
      const { to_url } = this.jumpData;
      const _jumpUrl = `${to_url}?userId=${loginName}&productId=zr`;

      if ( window.parent ) {
        window.parent.location.href = _jumpUrl;
      } else {
        window.open( _jumpUrl );
      }

    },
    /**
     * @生成图片二维码
     */
    randomNum( min, max ) {
      return Math.floor( Math.random() * ( max - min ) + min );
    },
    /**
     * @重置验证码
     */
    refreshCode() {
      this.identifyCode = '';
      this.makeCode( this.identifyCodes, 4 );
    },
    makeCode( o, l ) {
      for ( let i = 0; i < l; i++ ) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum( 0, this.identifyCodes.length )];
      }
      console.log( this.identifyCode );
    }
  }
};
</script>
<style lang="less" scoped>
._Initialization-app();

#app {
  ._Mx-backImage(100vw, 100vh, '@/assets/img/background-img.png');
  ._Mx-flex-start-center-column();
  padding-top: 1.80rem;

  h1 {
    font-size: 0.24rem;
    color: #fff;
  }
}

.user-name-register-view {
  margin-top: 0.20rem;
  width: 3rem;

  ::v-deep.van-cell {
    margin: 0.2rem 0;
    height: 0.46rem;
    border-radius: 0.22rem;
    background: rgba(255, 255, 255, 0.25);
  }

  ::v-deep.van-icon {
    font-size: 0.22rem;
  }

  ::v-deep.van-field__control {
    box-sizing: border-box;
    padding-left: 0.1rem;
    color: #fff;
    font-size: 0.16rem;
  }

  ::v-deep.van-button--info {
    color: #c52f3d;
    font-weight: normal;
    font-size: 0.18rem;
    font-weight: 600;
    background: linear-gradient(354.96deg, #e0c3a0 4.84%, #f9e8c0 95.43%);
  }

  ::v-deep.van-field__label {
    margin-right: 0;
    color: #fff;
    width: auto;

    & > span {
      ._Mx-flex-between-center();
      box-sizing: border-box;
      padding-bottom: 0.1rem;
      width: 0.16rem;
      height: 0.30rem;
      font-size: 0.20rem;
      font-weight: normal;
      position: relative;
      &::after {
        content: '';
        height: 0.12rem;
        width: 0.05px;
        background-color: #fff;
        margin-top: 0.04rem;
      }
    }
  }

  .verifCode-view {
    position: relative;
    ::v-deep.van-field__control {
      margin-bottom: 0.22rem;
    }

    .identifyCode-view {
      position: absolute;
      right: 0;
      top: -0.03rem;
    }
  }
}

.download-view {
  width: 3rem;
  height: 0.42rem;
  position: fixed;
  bottom: 0.8rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;

  img {
    width: 3rem;
    height: 0.42rem;
  }
}
</style>
