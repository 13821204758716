/**
 * 首页
 */

export default [
  // {
  //   path: '',
  //   name: 'Home',
  //   redirect: '/home'
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   meta: {
  //     title: 'One体育',
  //     keepAlive: true
  //   },
  //   component: () => import( /* webpackChunkName: "Home" */ '@/pages/Home' )
  // }
];
