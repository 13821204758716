/** 设置像素大小 */
import './initRem';

/** vant组件 */
import './vant';
// vant样式文件引入
import 'vant/lib/index.css';

/** Vconsole */
// import VConsole from 'vconsole';
// new VConsole();
// const _vdom = document.getElementsByClassName( 'vc-switch' )[0];
// _vdom.textContent = 'debug';
// _vdom.style['height'] = '50px';
// _vdom.style['width'] = '50px';
// _vdom.style['padding'] = '0';
// _vdom.style['display'] = 'flex';
// _vdom.style['align-items'] = 'center';
// _vdom.style['justify-content'] = 'center';
// _vdom.style['border-radius'] = '50%';
// _vdom.style['font-weight'] = 'bold';
// _vdom.style['background-image'] =
//   'linear-gradient(-225deg, #FF3CAC 0%, #562B7C 52%, #2B86C5 100%)';

/**
 * 全局插件引入
 */
import Vue from 'vue';

/** 复制粘贴插件 */
import VueClipboard from 'vue-clipboard2';
Vue.use( VueClipboard );

/** 懒加载 */
import VueLazyload from 'vue-lazyload';
Vue.use( VueLazyload );

/**
 * animate.css
 */
import 'animate.css';
