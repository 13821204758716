import Vue from 'vue';
import VueRouter from 'vue-router';
import home from './home';

Vue.use( VueRouter );

// 路由配置
const _routerMap = [ ...home ];

const createRouter = () =>
  new VueRouter( {
    mode: 'history',
    scrollBehavior: () => ( {
      y: 0
    } ),
    routes: _routerMap
  } );

const _router = createRouter();
const defaultTitle = 'One 游戏';
_router.beforeEach( ( to, from, next ) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  // 此处控制当前页面是否需要使用秘串登录
  if ( to.meta.noLogin ) {
    window.noLogin = true;
  } else {
    window.noLogin = false;
  }
  next();
} );

export function resetRouter() {
  const newRouter = createRouter();
  _router.matcher = newRouter.matcher;
}

export default _router;
